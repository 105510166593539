<!--
 * @Author: 张博洋
 * @Date: 2021-07-12 19:57:57
 * @LastEditTime: 2022-03-08 16:27:24
 * @LastEditors: 张博洋
 * @Description:
 * @FilePath: /yfz-h5/src/App.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->
<template>
  <div class="full pr over flex-box flex-column">
    <!-- 内容 -->
    <div class="flex1 pr">
      <div class="full-pa" ref="appPage">
        <div class="full pr over">
          <transition :name="animateName">
            <!-- <keep-alive> -->
            <navigation>
              <router-view class="full-pa flex-box flex-column app-body" style="z-index: 1;">
              </router-view>
            </navigation>
          </transition>
          <transition name="fade">
            <div v-show="fullLoading" class="pr full flex-box middle" style="left: 0;top: 0; z-index: 9999999;background-color: rgba(255, 255, 255, 0.5);" v-notouch>
              <van-loading size="40" />
            </div>
          </transition>
          <div class="full-pa index10 flex-box middle no-pointer" style="background-color: rgba(255, 255, 255, 0.1);" v-show="showPageLoading">
            <div class="tc pd10 l-h-1 b radius10 shadows3 flex-box middle bg-white">
              <van-loading size="26" />
              <span class="f12 block ml10">正在加载...</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
// 开发环境：11363
// 测试环境：11366
// 生产环境：11367
export default {
  name: 'App',
  provide() {
    return {
      APP: this,
      roomId: '11366',
    }
  },
  data() {
    return {
      showLogin: false, //是否显示登录
      viewAnimate: 'fade',
      inApp: true,
      fullLoading: false,
      showPage: false, //页面主题内容是否展示
      startRouterChange: false,
      showPageLoading: false, //加载页面loading
      d: +new Date(),
      reloadFlag: false,
      styles: '',
      createStart: 0,
      loginFlag: false,
      token: '',
    }
  },
  watch: {
    $route: {
      handler: function (newv, oldv) {
        console.log('route jiance ===========')
        //对比新老数据
        // console.log(newv, 111)
        if (newv.name) {
          this.createStart++
          console.log(this.createStart)
          if (this.createStart == 1) {
            this.appRouterCreate()
          }
        }
      },
      immediate: true,
    },
  },
  components: {},
  created() {
    this.$._self = this
    this.$.APP = this
    this.init()
    this.windowFocus()
  },
  mounted() {
    console.log(this.$)
  },
  computed: {
    animateName() {
      //动画效果计算
      if (!this.startRouterChange || this.reloadFlag) {
        return 'fade'
      } else {
        return this.viewAnimate
      }
    },
    activityFlag() {
      let flag = false
      return flag
    },
  },
  methods: {
    init() {
      this.$navigation.cleanRoutes()
      // 初始化根组件
      // 前进
      this.$navigation.on('forward', () => {
        // console.log('forwar')
        this.viewAnimate = 'fold-left'
      })
      // 刷新
      this.$navigation.on('replace', () => {
        // alert()
        if (this.$.replaceFlag) {
          this.viewAnimate = 'fade'
          this.$.replaceFlag = false
        } else {
          this.viewAnimate = 'fold-left'
        }
        // this.viewAnimate = 'fade';
      })
      //后退
      this.$navigation.on('back', () => {
        // console.log('back')
        this.viewAnimate = 'fold-right'
      })
    },
    windowFocus() {
      document.addEventListener('visibilitychange', () => {
        if (!document.hidden) {
          //处于当前页面
          // do something
          // this.$.getUserInfo().then((userInfo) => {
          //     if (userInfo.pin != this.$.userInfo.pin) {
          //         // this.$.toast.warning(`用户登录态发生了变化，我要刷新页面了哦`);
          //         window.location.reload();
          //     } else {
          //         this.$.userInfo = userInfo;
          //     }
          // });
          console.log('处于当前页面啦')
          // this.getUserInfo();
        }
      })
    },
    appRouterCreate() {
      //路由初始化之后的回调，created之后有可能router有问题
      console.log('appRouterCreate==============')
      window.app = this
      this.beforeEachAction()
      this.getUserInfo()
      try {
        this.$.setTitle(this.$route.meta.title)
      } catch (error) {}
    },
    beforeEachAction() {
      //路由变化监听
      this.$router.beforeEach((to, from, next) => {
        this.startRouterChange = true
        //关闭全局loading
        this.fullLoading = false
        this.showPageLoading = true
        //需要登录的判断
        if (to.meta && !to.meta.AppNoLogin && !this.$.loginFlag) {
          //this.$.goLogin(this.$.getFullPath(to), next);
          const url = window.location.href
          wx.miniprogram.redirectto({
            url: `/pages/login/login?url=${encodeURIComponent(url)}`,
          })
          this.showPageLoading = false
        } else {
          next()
        }
        next()
      })
      this.$router.afterEach((to, from) => {
        this.showPage = true
        try {
          document.title = to.meta.title
        } catch (error) {
          document.title = '豫中一'
        }
        this.showPageLoading = false
      })
    },
    getUserInfo() {
      //获取用户信息token
      console.log('获取用户信息=======')
      const winUrl = window.location.href
      const loginToken = this.$.getQueryString('token')
      // const refreshToken = this.$.getQueryString('refreshToken')
      if (winUrl.indexOf('token') !== -1 && !loginToken) {
        // alert('没登陆态')
        console.log('111111111=========')
        localStorage.removeItem('loginToken')
        // localStorage.removeItem('refreshToken')
        this.$.loginFlag = false
      }
      if (loginToken) {
        console.log('2222222=========')
        // alert('有登陆态')
        this.$.loginFlag = true
        localStorage.setItem('loginToken', loginToken)
        // localStorage.setItem('refreshToken', refreshToken)
        this.$.loginFlag = true
      } else if (localStorage.getItem('loginToken')) {
        this.$.loginFlag = true
      }
      console.log(
        '33333333=====================',
        !this.$route.meta.AppNoLogin,
        !this.$.loginFlag
      )
      if (!this.$route.meta.AppNoLogin && !this.$.loginFlag) {
        console.log('44444444444============')
        const url = window.location.href
        wx.miniprogram.redirectto({
          url: `/pages/login/login?url=${encodeURIComponent(url)}`,
        })
        this.showPage = false
      } else {
        this.showPage = true
      }
    },
  },
}
</script>

<style lang="scss">
@import './assets/app.scss';

.app-body {
  background-color: color(bg);
}
</style>
