/*
 * @Author: 张博洋
 * @Date: 2021-08-21 14:25:30
 * @LastEditTime: 2022-02-24 19:11:03
 * @LastEditors: 张博洋
 * @Description:
 * @FilePath: /yfz-h5/src/router/im.js
 * 可以输入预定的版权声明、个性签名、空行等
 */
export default [
  {
    path: "/im/chat",
    name: "im.chat",
    meta: {
      edit: true,
      title: "",
      route: {},
      AppNoLogin: true
    },
    component: () => import("@v/im/index.vue")
  },
  {
    path: "/im/history",
    name: "im.history",
    meta: {
      edit: true,
      title: "",
      route: {},
      AppNoLogin: true
    },
    component: () => import("@v/im/historyList.vue")
  },
  {
    path: "/im/prescroption",
    name: "im.prescription",
    meta: {
      edit: true,
      title: "原始处方",
      route: {},
      AppNoLogin: true
    },
    component: () => import("@v/im/prescroption.vue")
  },
  {
    path: "/im/record",
    name: "im.record",
    meta: {
      edit: true,
      title: "原始病历",
      route: {},
      AppNoLogin: true
    },
    component: () => import("@v/im/record.vue")
  }
];
