/*
 * @Author: 张博洋
 * @Date: 2021-07-22 14:52:04
 * @LastEditTime: 2021-12-28 17:31:58
 * @LastEditors: 张博洋
 * @Description:
 * @FilePath: /yfz-h5/src/router/city.js
 * 可以输入预定的版权声明、个性签名、空行等
 */
export default [
  {
    path: "/city/list",
    name: "city.list",
    meta: {
      done: false,
      title: "选择城市",
      route: {}
    },
    component: () => import("../views/city/list.vue")
  },

];