import Vue from "vue";
import axios from "axios";
import $ from "./utils.js";
//配置axios
//缓存本页面数据参数
var urlCache = {};
// var routerFlag = true; //路由变化是否是其他页面，默认path变化
setTimeout(() => {
  if ($._self.$router) {
    $._self.$router.beforeEach((to, from, next) => {
      //路由监听
      if (to.path != from.path) {
        urlCache = {};
      }
      next();
    });
  }
}, 100);
window.windowCache = {};
window.fullLoading = "";
//永久地址缓存
// function clearCache() {
//     setTimeout(() => {
//         urlCache = {};
//         clearCache();
//     }, 1000 * 60 * 60 * 12);
// } //12小时缓存失效
let $axios = obj => {
  let statrAxiosObj = $.extend(true, {}, obj); //外部
  let ajaxData = ""; //res数据
  let a = {
    url: "",
    type: "get", //请求方法,默认为get
    data: {}, //需要传递参数
    elseData: {
      //用于其他扩充
      token: true, //默认请求需要token
      type: $.type, //判断不同来源的接口预先定义不同的方式 yzd,   大类
      typeModule: "", //大类下面的可归类为一类请求的接口说明
      cache: false, //是否缓存接口
      loading: false, //是否需要提交加载
      uploadData: false, //上传图片传递数据开关
      formData: true, //判断数据是否需要formData
      tip: false,
      timeout: 30000,
      tipTxt: "提交成功！",
      tipTime: 1000,
      alias: {},
      cross: {
        yao: false,
        realname: false //首营平台实名认证接口走yao.jd.com
      },
      errorList: [], //ajax code返回特定code不需要提示，调用方接管示错
      errorListFn: () => { },
      tipTxtFlag: false, //强制使用tipTxt文案
      tipFn: () => { },
      arrayEmptyNull: true, //空数组处理成null
      emptyDelete: false,
      nullDelete: false, //去除传递参数第一层为null的值，将其变为‘
      fail() { }, //程序错误，服务器返回不成功，以及success为false的时候都会有该回调
      ajaxFail() { }, //请求返回success为false回调
      string: true, //是否需要进行数据转换
      ajaxLoading: false, //页面loading提示，一般用于table搜索
      btnLoading: false, //用于按钮提交验证
      btnLoadingName: "btnLoading",
      errorTip: true,
      loginCodeFlag: true //默认errorList添加登录code
    },
    config: {
      //设置即将被发送的自定义请求头
      headers: {
        "Cache-Control": "no-cache"
      }
    },
    _self: {}
  };
  obj = $.extend(true, a, obj); //合并参数
  // console.log(obj.elseData)
  //替换一些不符合规则的参数
  for (let key in obj.elseData.alias) {
    // console.log(key)
    let o = obj.elseData.alias[key];
    obj.data[o] = obj.data[key];
    if (key != o) {
      delete obj.data[key];
    }
  }
  //去除传递参数第一层为null的值，将其变为‘’
  if (obj.data && obj.elseData.nullDelete) {
    for (let key in obj.data) {
      if (obj.data[key] === null) {
        obj.data[key] = "";
      }
    }
  }
  if (obj.data && obj.elseData.arrayEmptyNull) {
    //空数组处理成null；
    for (let key in obj.data) {
      if (
        $.getType(obj.data[key]) == "array" &&
        obj.data[key].length == 0
      ) {
        obj.data[key] = null;
      }
    }
  }
  if (obj.data && obj.elseData.emptyDelete) {
    for (let key in obj.data) {
      if (obj.data[key] === null || obj.data[key] === "") {
        delete obj.data[key];
      }
    }
  }
  //判断是否需要数据转换
  let n = {
    loading(flag) {
      //全局loading
      if (obj.elseData.loading) {
        $._self.fullLoading = flag;
      }
    },
    ajaxLoading(flag, res) {
      if (obj.elseData.ajaxLoading) {
        var _self = obj._self;
        // console.log(flag)
        if (flag) {
          _self.APPloading = false;
          if (res.data.code === 0) {
            _self.APPerror = false;
          } else {
            _self.APPerror = true;
            _self.APPerrorMsg = res.data.msg;
          }
        } else {
          _self.APPerrorMsg = res;
          _self.APPloading = false;
          _self.APPerror = true;
        }
      }
    },
    btnLoading(flag, res) {
      if (obj.elseData.btnLoading) {
        var _self = obj._self;
        // console.log(flag)
        if (flag) {
          _self[obj.elseData.btnLoadingName] = false;
          if (res.data.code === 0) {
            _self.btnError = false;
          } else {
            _self.btnError = true;
            _self.btnErrorMsg = res.data.msg || res.data.message;
          }
        } else {
          _self.btnErrorMsg = res;
          _self[obj.elseData.btnLoadingName] = false;
          _self.btnError = true;
        }
      }
    },
    cache(res) {
      if (obj.elseData.cache) {
        if (res.data.code === 0) {
          urlCache[p] = $.extend(true, {}, res);
        }
      }
    },
    windowCache(res) {
      if (obj.elseData.windowCache) {
        if (res.data.code === 0) {
          windowCache[p] = $.extend(true, {}, res);
        }
      }
    },
    ls(res) {
      if (obj.elseData.ls) {
        if (res.data.code === 0) {
          localStorage.setItem(ls, JSON.stringify(res));
        }
      }
    },
    tip(res) {
      if (obj.elseData.tip) {
        if (res.data.code === 0) {
          // console.log(111)
          let m =
            res.data.msg || res.data.message || obj.elseData.tipTxt;
          if (obj.elseData.tipTxtFlag) {
            m = obj.elseData.tipTxt;
          }
          $.toast.success(m);
          obj.elseData.tipFn(res);
          // $.tip({
          //     time: obj.elseData.tipTime,
          //     content: res.data.msg || obj.elseData.tipTxt,
          //     fn: obj.elseData.tipFn
          // })
        }
      }
    }
  };
  if (obj.elseData.uploadData) {
    let fd = new FormData();
    for (const key in a.data) {
      fd.append(key, a.data[key]);
    }
    a.data = fd;
  } else if (obj.elseData.string) {
    obj.config.transformRequest = [
      function (data) {
        return JSON.stringify(data);
      }
    ];
    obj.config.headers["Content-Type"] = "application/json";
  } else if (obj.elseData.formData) {
    obj.config.transformRequest = [
      function (data) {
        let ret = "";
        for (let it in data) {
          ret +=
            encodeURIComponent(it) +
            "=" +
            encodeURIComponent(data[it]) +
            "&";
        }
        return ret;
      }
    ];
    obj.config.headers["Content-Type"] =
      "application/x-www-form-urlencoded";
  }
  // if (obj.elseData.type == 'yzd') {
  obj.config.headers["x-requested-with"] = "XMLHttpRequest";
  // }
  const loginToken = localStorage.getItem("loginToken");
  const refreshToken = localStorage.getItem("refreshToken");
  //token存缓存待优化
  if (loginToken && obj.elseData.token) {
    obj.config.headers.token = loginToken
  }
  // if ($.userInfo.token && obj.elseData.token) {
  //     obj.config.headers.token = $.userInfo.token;
  // }
  // if (obj.elseData.cross.yao) {
  //     obj.config.headers.cross = 1000;
  // }
  // if ($.dev && $.currentRoute == 'gy') {
  //     obj.config.headers.frLo = "test_06125";
  // }
  // if ($.dev && $.currentRoute == 'static') {
  //     obj.config.headers['free-pin'] = "shijinlong";
  // }
  //判断是否需要缓存，如果有缓存，直接返回
  var p = obj.url + JSON.stringify(obj.data);
  if (urlCache[p] && obj.elseData.cache) {
    return new Promise(resolve => {
      //copy返回参数，避免操作参数进行不必要的修改
      var res = $.extend(true, {}, urlCache[p]);
      n.ajaxLoading(true, res);
      n.btnLoading(true, res);
      resolve(res);
    });
  }
  // 如果从local获取数据
  // let ls = obj.url + JSON.stringify(obj.data);
  // if (localStorage.getItem(ls) && (obj.elseData.ls || (obj.elseData.windowCache && windowCache[p]))) {
  //     return new Promise((resolve) => {
  //         resolve(JSON.parse(localStorage.getItem(ls)));
  //     })
  // }
  //页面刷新保留缓存
  if (windowCache[p] && obj.elseData.windowCache) {
    return new Promise(resolve => {
      //copy返回参数，避免操作参数进行不必要的修改
      var res = $.extend(true, {}, windowCache[p]);
      n.ajaxLoading(true, res);
      n.btnLoading(true, res);
      resolve(res);
    });
  }
  console.log(APPTypes)
  //发送请求函数
  let baseURL = {
    wx: {
      dev: $.config.api,
      product: $.config.api
    }
  };


  if (obj.elseData.btnLoading) {
    obj._self[obj.elseData.btnLoadingName] = true;
  }
  //9001拦截
  // if (obj.elseData.loginCodeFlag) {
  //   obj.elseData.errorList.push(...$.needLoginCode);
  // }
  // 公参传递
  let publicParams = {
    // clientType: 'm',
    // 'osVersion': '11.3.1',
    // // 'lon': '116.562301',
    // // 'lat': '39.787270',
    // 'uuid': 'd1ad1d4d94ae42c0bab54c0bd4813676',
    // 'clientVersion': '2.5.5',
    // 'brand': 'iPhone',
    // 'model': 'iPhone10,3',
    // 'networkType': 'wifi'
  };
  // if (!jdm) {
  //     publicParams.shareTag = 'factory';
  // }

  let newAxios = axios(
    $.extend(
      true,
      {
        url: obj.url,
        method: obj.type,
        headers: obj.headers,
        baseURL: (() => {
          let url = "";
          console.log(obj.url)
          if (
            obj.url.indexOf("https") === 0 ||
            obj.url.indexOf("http") === 0
          ) {
            return "";
          }
          url = baseURL[obj.elseData.type][$.dev ? "dev" : "product"];
          console.log(url)
          return url;
        })(),
        withCredentials: true,
        timeout: obj.elseData.timeout,
        data: obj.type === "get" ? {} : a.data,
        params: $.extend(
          true,
          publicParams,
          obj.type === "post" ? {} : a.data
        )
      },
      obj.config
    )
  )
    .then(res => {
      // TOKEN_EXPIRE("10303","登录已过期，刷新token"),
      //token失效之后重新请求token
      // console.log($.refreshTokenCode);
      // console.log(res.data.code);
      // if ($.refreshTokenCode.includes(res.data.code)) {
      //   let url = "/p/user/refreshToken";
      //   return $._self
      //     .$axios({
      //       type: "post",
      //       data: {
      //         //refreshToken: $.userInfo.refreshToken
      //         refreshToken: refreshToken
      //       },
      //       elseData: {
      //         token: false
      //       },
      //       url: url
      //     })
      //     .then(res => {
      //       //成功之后让之前请求重新开始
      //       //$.userInfo = res.d;
      //       localStorage.removeItem("loginToken");
      //       localStorage.removeItem("refreshToken");
      //       localStorage.setItem("loginToken", res.d.token);
      //       localStorage.setItem(
      //         "refreshToken",
      //         res.d.refreshToken
      //       );
      //       return $axios(statrAxiosObj);
      //     })
      //     .catch(() => {
      //       //$.goLogin("");
      //       const url = window.location.href;
      //       wx.miniProgram.navigateTo({
      //         url: `/pages/login/login?url=${encodeURIComponent(
      //           url
      //         )}`
      //       });
      //     });
      // }
      //请求完成
      ajaxData = res.data;
      n.loading(false);
      if (res.data.code === 0) {
        //正确返回
        //请求返回数据成功
        // n.ajaxLoading(true, res);
        // n.btnLoading(true, res);
        res.codeFlag = true;
        res.d = res.data.data;
        // n.tip(res);
        // console.log(111)
        // n.cache(res);
        // console.log(222)

        // n.ls(res);
        // console.log(2233)

        // n.windowCache(res);

        return Promise.resolve(res);
      } else {
        //请求数据失败
        res.d = res.data.data;
        obj.elseData.ajaxFail(res);
        obj.elseData.fail(res);

        if ($.needLoginCode.includes(res.data.code)) {
          // 未登录和无用户
          obj.elseData.errorTip = false;
          if ($._self.$route.name != "login") {
            //$.goLogin("");
            const url = window.location.href;
            wx.miniprogram.redirectto({
              url: `/pages/login/login?url=${encodeURIComponent(
                url
              )}`
            });
          }
          return Promise.reject(
            new Error(res.data.msg || "请求出错")
          );
        } else if (
          obj.elseData.errorList.indexOf(res.data.code) != -1
        ) {
          //排除禁止提示的error
          obj.elseData.errorTip = false;
          if (
            obj.elseData.loginCodeFlag &&
            $.needLoginCode.indexOf(res.data.code) == -1
          ) {
            obj.elseData.errorListFn(res);
          } else {
            obj.elseData.errorListFn(res);
          }
          return Promise.reject(
            new Error(res.data.msg || "请求出错")
          );
        } else if ($.needConfirmLoginCode.includes(res.data.code)) {
          if ($._self.$route.name != "login") {
            obj.elseData.errorTip = false;
            const url = window.location.href;
            $._self.$dialog
              .alert({
                message: `${res.data.msg}`,
                confirmButtonText: "确定"
                // cancelButtonText: "返回"
              })
              .then(() => {
                wx.miniprogram.redirectto({
                  url: `/pages/login/login?url=${encodeURIComponent(
                    url
                  )}`
                });
              });
          }

          return Promise.reject(
            new Error(res.data.msg || "请求出错")
          );
        } else {
          return Promise.reject(
            new Error(res.data.msg || "请求出错")
          );
        }
      }
    })
    .catch(error => {
      console.log(error)
      let errorMsg = "服务器开小差，请稍后重试！";
      if (error && error.response) {
        try {
          ajaxData = error.response.data;
        } catch (error) { }
        switch (error.response.status) {
          case 400:
            errorMsg = "请求错误";
            break;

          case 401:
            errorMsg = "未授权，请登录";
            break;

          case 403:
            errorMsg = "拒绝访问";
            break;

          case 404:
            errorMsg = `请求地址出错: ${error.response.config.url}`;
            break;

          case 408:
            errorMsg = "请求超时";
            break;

          case 500:
            errorMsg = "服务内部错误";
            break;

          case 501:
            errorMsg = "服务未实现";
            break;

          case 502:
            errorMsg = "服务器开小差";
            break;

          case 503:
            errorMsg = "服务不可用";
            break;

          case 504:
            errorMsg = "网关超时";
            break;

          case 505:
            errorMsg = "HTTP版本不受支持";
            break;

          default:
            errorMsg = "服务器出错，接口异常";
            break;
        }
      }
      if (ajaxData && (ajaxData.msg || ajaxData.message)) {
        errorMsg = ajaxData.msg || ajaxData.message;
      }
      if (obj.elseData.errorTip) {
        $.toast.error(errorMsg);
      }
      n.loading(false);
      // console.log(errorMsg)
      n.ajaxLoading(false, errorMsg);
      n.btnLoading(false, errorMsg);
      obj.elseData.fail(ajaxData);
      return Promise.reject(ajaxData.msg || ajaxData.message || errorMsg);
    });
  //请求之前处理逻辑
  n.loading(true); //提交数据loading
  return newAxios;
};
let fetchData = (url, type, data, param = {}) => {
  const params = {
    type,
    url: "/p/" + url,
    data,
    elseData: Object.assign(
      {
        string: true,
        ajaxLoading: true,
        errorTip: false
      },
      param.elseData
    )
    // _self: param._self || null
  };
  if (param._self) {
    params._self = param._self;
  }
  return new Promise(resolve => {
    $axios(params)
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        resolve(e);
      });
  });
};
Vue.prototype.$axios = $axios;
Vue.prototype.$axiosP = axios;
Vue.prototype.$fetchData = fetchData;

export { $axios };

