/*
 * @Author: 张博洋
 * @Date: 2021-08-06 16:33:41
 * @LastEditTime: 2021-11-20 18:15:04
 * @LastEditors: 张博洋
 * @Description:
 * @FilePath: /hospital-h5/src/router/drug.js
 * 可以输入预定的版权声明、个性签名、空行等
 */
export default [
  {
    path: "/drug/list",
    name: "drug.list",
    meta: {
      edit: true,
      title: "购药订单",
      route: {},
      AppNoLogin: true
    },
    component: () => import("@v/drug/list.vue")
  },
  {
    path: "/drug/detail",
    name: "drug.detail",
    meta: {
      edit: true,
      title: "订单详情",
      route: {},
      AppNoLogin: true
    },
    component: () => import("@v/drug/detail.vue")
  },
  {
    path: "/drug/storeList",
    name: "drug.storeList",
    meta: {
      edit: true,
      title: "请选择自提门店",
      route: {},
      AppNoLogin: true
    },
    component: () => import("@v/drug/storeList.vue")
  },
];
