export default [
    {
        path: "/agreement/agreement",
        name: "agreement.agreement",
        meta: {
            done: false,
            title: "",
            route: {},
        },
        component: () => import("../views/agreement/agreement.vue"),
    },
];
