/*
 * @Author: 张博洋
 * @Date: 2021-07-15 10:08:20
 * @LastEditTime: 2022-03-03 19:45:23
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /yfz-h5/src/public/utils.js
 * 可以输入预定的版权声明、个性签名、空行等
 */
var _ = require('lodash');
import extend from "extend";
import moment from "moment";
let testFlag = process.env.testFlag;
let devFlag = process.env.NODE_ENV == "development";
var $ = {
  install(Vue) {
    Vue.prototype.$ = this;
  },
  APPTypes: APPTypes,
  isAndroid: (() => {
    var u = navigator.userAgent.toLowerCase();
    return u.indexOf("android") > -1 || u.indexOf("linux") > -1; //android终端或者uc浏览器
  })(),
  isIos: (() => {
    var u = navigator.userAgent;
    return (
      !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) ||
      u.indexOf("iPhone") != -1
    ); //ios终端
  })(),
  isPc: (() => {
    var userAgentInfo = navigator.userAgent;
    var Agents = [
      "Android",
      "iPhone",
      "SymbianOS",
      "Windows Phone",
      "iPad",
      "iPod"
    ];
    var flag = true;
    for (var v = 0; v < Agents.length; v++) {
      if (userAgentInfo.indexOf(Agents[v]) > 0) {
        flag = false;
        break;
      }
    }
    return flag;
  })(),
  getQueryString(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) {
      return unescape(r[2]);
    }
    return null;
  },
  isWeiXin: (() => {
    //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
    var ua = window.navigator.userAgent.toLowerCase();
    //通过正则表达式匹配ua中是否含有MicroMessenger字符串
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      return true;
    } else {
      return false;
    }
  })(),
  // TOKEN_EXPIRE("10303","登录已过期，刷新token"),
  //token失效之后重新请求token
  refreshTokenCode: ["10303", "10320"],
  //PARAM_NOT_COMPLETE("10301","未登录"),
  // INVALID_PAssword("10302","密码无效，请重新登录"),
  // INVALID_USER("10304","无效账户"),
  // INVALID_MOBILE("10305","手机号无效"),
  // DOCTOR_ACCESS_DENIED("10306","您未被授权登录该APP"),
  // MANAGE_ACCESS_DENIED("10307","您没有操作权限"),
  // USER_LOGIN_ERROR("10308", "账号不存在或密码错误"),
  // USER_NOT_EXIST("10309", "用户不存在"),
  // REFRESH_TOKEN_ERROR("10317","刷新token失败"),
  //VALID_LOGIN_ERROR("10319", "登录验证失败"),
  // INVALID_CODE("10359","code无效"),
  needLoginCode: [
    "10301",
    "10302",
    // "10303",
    "10304",
    "10305",
    "10306",
    "10307",
    "10308",
    "10309",
    "10317",
    "10319",
    "10359"
  ],
  // LOGIN_OTHER_APPLET("10362","该手机号已在其他微信上登录")
  needConfirmLoginCode: ["10362"],
  reg: {
    //正则表达式
    url: /^https?:\/\/(([a-zA-Z0-9_-])+(\.)?)*(:\d+)?(\/((\.)?(\?)?=?&?[a-zA-Z0-9_-](\?)?)*)*$/i,
    jdUrl: /^((http)|(https)|(openapp.jdmedicine))?:\/\/(([a-zA-Z0-9_-])+(\.)?)*(:\d+)?\.jd\.com(\/.*)?$/i,
    pcjdUrl: /^((http)|(https))?:\/\/(([a-zA-Z0-9_-])+(\.)?)*(:\d+)?\.jd\.com(\/.*)?$/i,
    // pcjdUrl: /^((http)|(https))?:\/\/(([a-zA-Z0-9_-])+(\.)?)*(:\d+)?\.jd\.com(\/.*)?$/i,
    // jdUrl: /^((http)|(https)|(openapp.jdmedicine))?:\/\/[^.]*\.jd\.com(\/.*)?$/i,
    emoji: /\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDE4F]/g,
    engNumber: /^[a-z0-9]+$/i,
    name: /^[\u4e00-\u9fa5]{1,100}$|^[A-Za-z]+\/[A-Za-z]+( [A-Za-z]+)?$|^[\u4e00-\u9fa5]+[a-zA-Z]+$/,
    email: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
    tel: /^1[0-9]\d{9}$/,
    tel344: /^1[0-9]\d{1}\s[\d\*]{4}\s\d{4}$/,
    idNum: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
    peopleId: /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/,
    PSP: /^[A-Za-z0-9]{2}\d{7}$/, // 护照（http://www.voidcn.com/blog/sunkaikaisun/article/p-3766234.html）
    HKM: /^[HMhm]{1}([0-9]{10}|[0-9]{8})$/, // 港澳通行证
    TW: /^\d{8}$/, // 台湾通行证
    MOC: /^[\u4e00-\u9fa5]{1}字第\d{6,7}$/, //军官证、士兵证格式为:*字第+6/7位数字
    numAndLetter: /^[A-Za-z0-9]*$/, //数字和英文
    num: /^[0-9\*]+$/, //数字或*
    hasChinese: /^.*[\u4e00-\u9fa5].*/, // 必须包含汉字
    allChinese: /^[\u4e00-\u9fa5]+$/, // 全部汉字
    tax: /^[A-Z0-9]{15,20}$/, //企业税号
    tax1: /^[A-Za-z0-9\-]{10,30}$/, //企业税号带“-”
    fullName: /^[A-Za-z0-9\s\u4e00-\u9fa5]*$/,
    userName: /^[A-Za-z0-9\-\_\u4e00-\u9fa5]*$/,
    pwd: /^[\w`\-=[\]\\;',./~!@#$%^&*()+{}|:"<>?～！@#¥%……&*（）——+「」|："《》？]*$/ // 英文，数字，字符
  },
  dev: devFlag,
  moment,
  extend,
  _,
  created(fn, time) {
    let s = setTimeout(() => {
      fn && fn();
      clearTimeout(s);
    }, time || 300);
  },
  setTitle(title) {
    console.log($)
    let s = setTimeout(() => {
      document.title = $._self.$root.title = title || ""
      clearTimeout(s);
    }, 0);
  },
  swiperOption: {
    banner: {
      //适用于banner场景
      // preloadImages:false,//默认为true，Swiper会强制加载所有图片。
      // nested: true,
      watchOverflow: true,
      autoplay: {
        disableOnInteraction: false
      },
      // loop:true,
      freeModeMomentumBounce: false,
      delay: 5000,
      pagination: {
        el: ".swiper-pagination",
        clickable: true
      },
      initialSlide: 0
    },
    tab: {
      resistanceRatio: 0,
      iOSEdgeSwipeDetection: true,
      noSwiping: true,
      noSwipingClass: "stop-swiping"
    }
  },
  // imgs: {
  //   time:
  //     "//img11.360buyimg.com/imagetools/jfs/t1/108049/29/1982/1265/5e02c703Ed616ac6a/4360fd6e4d65b0ee.png",
  //   time1:
  //     "//img14.360buyimg.com/imagetools/jfs/t1/104853/8/8173/1534/5e02c703E9f87e9da/c007890af5b68be8.png",
  //   APPlogo:
  //     "//img14.360buyimg.com/imagetools/jfs/t1/89557/38/6530/23346/5df37f6eE05ace358/32fcaa127f2f1bc5.jpg",
  //   lose: {
  //     wifi:
  //       "//img11.360buyimg.com/imagetools/jfs/t1/110000/25/1951/98402/5e02cdcfEe847e5a6/73af5dfc48793ae1.png",
  //     message:
  //       "//img13.360buyimg.com/imagetools/jfs/t1/98519/24/8242/109557/5e02cdcfEe0d60f74/2f6fa19b7c5354e5.png",
  //     list:
  //       "//img11.360buyimg.com/imagetools/jfs/t1/96315/12/7306/62085/5dfa2339E490cad2c/b599a0006b1196b5.png",
  //     empty:
  //       "//img12.360buyimg.com/imagetools/jfs/t1/92750/33/8174/106818/5e02cdcfE993ba06f/13ec5ed4c93c9920.png",
  //     coupon:
  //       "//img12.360buyimg.com/imagetools/jfs/t1/105063/3/8519/119791/5e0607ceE74d2d0bf/52048d711ad0e4ac.png",
  //     search:
  //       "//img12.360buyimg.com/imagetools/jfs/t1/85363/38/8133/134619/5e02cdcfE48758520/0ae9c1ec7b3cd6f0.png",
  //     lock:
  //       "//img14.360buyimg.com/imagetools/jfs/t1/109089/6/2442/100960/5e0606feE71401a58/f67bf45bb535310f.png",
  //     bank:
  //       "//img13.360buyimg.com/imagetools/jfs/t1/98793/29/8588/95048/5e0606fdE23626dd8/69ad5e587b3dedf2.png",
  //     store:
  //       "//img14.360buyimg.com/imagetools/jfs/t1/86010/3/8658/87192/5e0607edE5eb4a071/cfa74c8adb43845f.png"
  //   }
  // },
  toast: (() => {
    let obj = {
      warning: {
        icon: "info"
      },
      error: {
        icon: "clear"
      },
      success: {
        icon: "passed"
      }
    };
    let a = {};
    for (const key in obj) {
      a[key] = m => {
        if (key == "success") {
          return $._self.$toast({
            message: `<div class="flex-box middle f12 flex-column tc"><i class="van-icon mt15 van-icon-${obj[key].icon} f30"></i> <span class='f15 mt15 mb10'>${m}</span></div>`,
            type: "html",
            transition: "fade"
          });
        } else {
          return $._self.$toast({
            message: `<div class="flex-box middle f12"><i class="van-icon van-icon-${obj[key].icon} f20"></i> ${m}</div>`,
            type: "html",
            transition: "fade"
          });
        }
      };
    }
    return a;
  })(),
  getType(obj) {
    //获取数据格式
    try {
      var types = {};
      var arr = "Boolean Number String Function Array Date RegExp Object Error Symbol".split(
        " "
      );
      for (var i = 0; i < arr.length; i++) {
        types["[object " + arr[i] + "]"] = arr[i].toLowerCase();
      }
      return obj == null
        ? String(obj)
        : types[Object.prototype.toString.call(obj)] || "object";
    } catch (error) {
      return "";
    }
    // console.log(getType(null));//null
    // console.log(getType('abc'));//string
    // console.log(getType(111));//number
    // console.log(getType({a:1}));//object
    // console.log(getType([1,2,3]));//array
    // console.log(getType(function(){}));//function
    // console.log(getType(true));//boolean
    // console.log(getType(undefined));//undefined
  },
  imgs: {
    'userAvatar': 'https://hospital-1252497236.cos.ap-beijing.myqcloud.com/yfz_dev/avatar/icon%E5%9B%BE%E6%A0%87%EF%BC%8F%E9%80%9A%E7%94%A8%EF%BC%8F%20%E6%82%A3%E8%80%85%E9%BB%98%E8%AE%A4%E5%A4%B4%E5%83%8F_64%402x.png'
  },
  type: APPTypes.type,  //wx
  config: APPTypes.config,
  apiType: (() => {
    let type = APPTypes.type;
    if (type == "doctor") {
      return "/d/";
    } else {
      return "/patient/";
    }
  })(),
  replyTime(timestamp) {
    return $.moment(timestamp).format('YYYY.MM.DD HH:mm:ss')
  },
  replyTimeToDay(timestamp) {
    return $.moment(timestamp).format('YYYY.MM.DD')
  },
  getPositiont(el) {
    if (!el) {
      return {
        left: 0,
        top: 0
      };
    }
    const box = el.getBoundingClientRect();

    return {
      left: el.offsetLeft,
      top: el.offsetTop
    };
  },
}

$.rules = {
  tel: {
    pattern: $.reg.tel,
    message: "格式不正确"
  },
  peopleId: {
    pattern: $.reg.peopleId,
    message: "格式不正确"
  },
  hkm: {
    pattern: $.reg.HKM,
    message: "格式不正确"
  },
  psp: {
    pattern: $.reg.PSP,
    message: "格式不正确"
  },
  tw: {
    pattern: $.reg.TW,
    message: "格式不正确"
  },


  oldTel(tel) {
    return {
      message: "格式不正确",
      validator(value, rule) {
        if (value != tel || !$.reg.tel.test(value)) {
          return false;
        } else {
          return true;
        }
      }
    };
  },
  required: {
    message: "",
    validator(value, rule) {
      if (value === "" || value === undefined || value === null) {
        return false;
      } else {
        return true;
      }
    }
  },
  max(n) {
    return {
      message: "不能大于" + n,
      validator(value, rule) {
        let v = Number(value);
        if (value && v > n) {
          return false;
        } else {
          return true;
        }
      }
    };
  },
  maxD(n) {
    return {
      message: "不能大于" + n,
      validator(value, rule) {
        let v = Number(value);
        if (value && v >= n) {
          return false;
        } else {
          return true;
        }
      }
    };
  },
  integer: {
    message: "必须为整数",
    validator(value, rule) {
      var v = Number(value);
      if (
        value &&
        v !== undefined &&
        v !== null &&
        v !== "" &&
        Math.floor(v) !== v
      ) {
        return false;
      } else {
        return true;
      }
    }
  },

  min(n) {
    return {
      message: "不能小于" + n,
      validator(value, rule) {
        let v = Number(value);
        if (value && v < n) {
          return false;
        } else {
          return true;
        }
      }
    };
  },
  minD(n) {
    return {
      message: "不能小于" + n,
      validator(value, rule) {
        let v = Number(value);
        if (value && v <= n) {
          return false;
        } else {
          return true;
        }
      }
    };
  },
  maxMin(max, min) {
    return {
      message: `范围：${min}~${max}`,
      validator(value, rule) {
        let v = Number(value);
        if (value && (v > max || v < min)) {
          return false;
        } else {
          return true;
        }
      }
    };
  },
  maxDMin(max, min) {
    return {
      message: `范围：${min}~${max}`,
      validator(value, rule) {
        let v = Number(value);
        if (value && (v >= max || v < min)) {
          return false;
        } else {
          return true;
        }
      }
    };
  },
  maxMinD(max, min) {
    return {
      message: `范围：${min}~${max}`,
      validator(value, rule) {
        let v = Number(value);
        if (value && (v > max || v <= min)) {
          return false;
        } else {
          return true;
        }
      }
    };
  }
};
export default $;