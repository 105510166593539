<template>
  <router-link :to="toPath"
               :target="target"
               v-if="routerFlag&&!spanFlag"
               :active-class="activeClass"
               :exact='exact'
               :exact-active-class="exactClass">
    <slot></slot>
  </router-link>
  <a :href="to"
     :target="target"
     v-else-if="!spanFlag"
     @click='linkClick'>
    <slot></slot>
  </a>
  <div v-else>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    target: {
      default: ""
    },
    to: {
      default: ""
    },
    activeClass: {
      default: ""
    },
    exact: {
      default: false
    },
    activeClasss: {
      default: ""
    },
    spanFlag: {
      default: false
    },
    exactClass: {
      default: ""
    }
  },
  data () {
    return {
      toPath: this.to,
      routerFlag: false
    };
  },
  watch: {
    to: {
      handler (to) {
        if (this.$.getType(to) == "object") {
          this.toPath = to;
          this.routerFlag = true;
          return false;
        }
        let newA = document.createElement("a");
        newA.setAttribute("href", to);
        let href = newA.href;
        let host = window.location.host;
        let reg = /^((https?:\/\/)|(\/\/))/i;
        //先判断是否为该域名下的路由
        let path = to;
        let p = href.replace(reg, "");
        let pathArr = p.split("/");
        // console.log(pathArr)
        if (pathArr[0] == host) {
          //本域名
          path = p.replace(host + "/m", "");
        } else if (
          this.$.dev &&
          pathArr[0] == this.$.yaoHost.replace("//", "") &&
          this.$.type == "index"
        ) {
          path = p.replace(this.$.yaoHost.replace("//", "") + "/m", "");
        } else if (
          this.$.dev &&
          pathArr[0] == "yao-shop.jd.com" &&
          this.$.type == "gzt"
        ) {
          path = p.replace("yao-shop.jd.com", "");
        } else {
          this.toPath = href;
          this.routerFlag = false;
        }

        //检查路由是否注册过
        if (path == "" || path == "/") {
          //首页
          this.toPath = "/";
          this.routerFlag = true;
        } else {
          // console.log(path,111)
          let r = this.$router.resolve(path);
          if (r.route.name && r.route.name != "404") {
            this.toPath = path;
            this.routerFlag = true;
          } else {
            if (this.$.dev) {
              //如果是开发环境
              this.toPath = this.$.yaoHost + path;
              this.routerFlag = false;
            }
            this.toPath = href;
            this.routerFlag = false;
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    linkClick (e) {
      if (jdm) {
        e.preventDefault();
        jdm.appOpenURL(
          JSON.stringify({
            url: this.to
          })
        );
      }
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
