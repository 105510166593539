<!--
 * @Author: 张博洋
 * @Date: 2021-10-15 19:02:00
 * @LastEditTime: 2021-11-26 11:06:01
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /hospital-h5/src/components/refresh/loading.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->
/*
* @Author: 严浩韦
* @description:loading动画
* @Date: 2020-04-25 18:33:35
 * @Last Modified by: 严浩韦
 * @Last Modified time: 2020-04-25 20:26:13
*/
<template>
  <div class="flex-box middle-a">
    <!-- <div class="pr mr10"
         :class="progress==2?'heartBeat animated infinite ':''">
      <div class="clearfix tran"
           style="width:24px;"
           :style='{transform:`rotate(${45*progress>45?45:45*progress}deg)`}'>
        <i class="radius4 bg-primary fl tran"
           v-for='item in 4'
           :key='item'
           style="width:10px;height:10px;margin:1px;"
           :style="{transform:`scale(${progress>1?1:progress})`}"></i>
      </div>
      <div class="full-pa tran"
           style="opacity:0"
           :style="{opacity:`${progress>=1?1:0}`}">
        <span class="bg-bg pa"
              style="width:18px;height:6px;left:50%;margin-left:-9px;top:50%;margin-top:-3px;"></span>
        <span class="bg-bg pa"
              style="width:18px;height:6px;left:50%;margin-left:-9px;top:50%;margin-top:-3px;transform:rotate(90deg)"></span>
      </div>
    </div> -->
    <span class="over text-nowrap f13 color-content"
          style="max-width:0; color:#999!important; font-size:14px!important"
          :style="{'max-width':`${progress>0.2?200:0}px`}">
      <!-- <span v-if='progress==3'>更新成功</span> -->
      <span v-if='progress==2'>加载中...</span>
      <span v-else-if='progress<1'>下拉即可刷新...</span>
      <span v-else>释放即可刷新...</span>
    </span>
  </div>

</template>

<script>
export default {
  props: {
    progress: {
      type: Number,
      default: 0
    },
  },
}

</script>

<style lang="scss" scoped>
</style>
