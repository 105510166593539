/*
 * @Author: 张博洋
 * @Date: 2021-07-22 14:52:04
 * @LastEditTime: 2021-11-15 14:11:40
 * @LastEditors: 张博洋
 * @Description:
 * @FilePath: /hospital-h5/src/router/address.js
 * 可以输入预定的版权声明、个性签名、空行等
 */
export default [
  {
    path: "/address/list",
    name: "address.list",
    meta: {
      done: false,
      title: "地址管理",
      route: {}
    },
    component: () => import("../views/address/list.vue")
  },
  {
    path: "/address/edit",
    name: "address.edit",
    meta: {
      done: true,
      hide: true,
      title: "编辑收货地址",
      route: {}
    },
    component: () => import("@/views/address/edit.vue")
  }
  // {
  //   path: "/address/edit/:id",
  //   name: "address.edit",
  //   meta: {
  //     hide: true,
  //     title: "修改收货地址",
  //     route: {
  //       params: {
  //         id: 1111
  //       }
  //     }
  //   },
  //   component: () => import("@v/wx/address/create.vue")
  // }
];

