/*
 * @Author: 张博洋
 * @Date: 2021-08-12 12:49:52
 * @LastEditTime: 2022-03-08 17:00:48
 * @LastEditors: 张博洋
 * @Description:
 * @FilePath: /yfz-h5/src/router/patient.js
 * 可以输入预定的版权声明、个性签名、空行等
 */
export default [
    {
        path: "/patient/admin",
        name: "patient.admin",
        meta: {
            edit: true,
            title: "就诊人管理",
            route: {},
            AppNoLogin: true,
        },
        component: () => import("@v/patient/admin.vue"),
    },
    {
        path: "/patient/detail",
        name: "patient.detail",
        meta: {
            edit: true,
            title: "",
            route: {},
            AppNoLogin: true,
        },
        component: () => import("@v/patient/detail.vue"),
    },
    {
        path: "/patient/add",
        name: "patient.add",
        meta: {
            edit: true,
            title: "就诊人信息",
            route: {},
            AppNoLogin: true,
        },
        component: () => import("@v/patient/edit.vue"),
    },
    {
        path: "/patient/edit",
        name: "patient.edit",
        meta: {
            edit: true,
            title: "就诊人信息",
            route: {},
            AppNoLogin: true,
        },
        component: () => import("@v/patient/edit.vue"),
    },

    {
        path: "/patient/editArchives",
        name: "patient.archives.edit",
        meta: {
            edit: true,
            title: "健康档案",
            route: {},
            AppNoLogin: true,
        },
        component: () => import("@v/patient/editArchives.vue"),
    },
    {
        path: "/patient/addArchives",
        name: "patient.archives.add",
        meta: {
            edit: true,
            title: "健康档案",
            route: {},
            AppNoLogin: true,
        },
        component: () => import("@v/patient/editArchives.vue"),
    },
    {
        path: "/patient/archives",
        name: "patient.archives",
        meta: {
            edit: true,
            title: "健康档案",
            route: {},
            AppNoLogin: true,
        },
        component: () => import("@v/patient/archives.vue"),
    },
];
