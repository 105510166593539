/*
 * @Author: 张博洋
 * @Date: 2021-08-09 18:15:57
 * @LastEditTime: 2021-11-19 18:01:43
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /hospital-h5/src/public/vant.js
 * 可以输入预定的版权声明、个性签名、空行等
 */
import Vue from "vue";

import {
  NavBar,
  Toast,
  Dialog,
  Image,
  Lazyload,
  Icon,
  Button,
  Sticky,
  Progress,
  Loading,
  CountDown,
  Popup,
  Circle,
  Overlay,
  Divider,
  Cell,
  CellGroup,
  RadioGroup,
  Radio,
  Tag,
  Popover,
  Field, PullRefresh, List
} from "vant";

Toast.allowMultiple();

Vue.use(NavBar);
Vue.use(Toast);
Vue.use(Dialog);
Vue.use(Image);
Vue.use(Overlay);

// options 为可选参数，无则不传
Vue.use(Lazyload, {
  attempt: 1,
  preLoad: 1.3
});
Vue.use(Icon);
Vue.use(Button);
Vue.use(Sticky);
Vue.use(Progress);
Vue.use(CountDown);
Vue.use(Loading);
Vue.use(Popup);
Vue.use(Circle);
Vue.use(Divider);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Tag);
Vue.use(Field);
Vue.use(Popover);

Vue.use(List);
Vue.use(PullRefresh);

// Vue.user(Rate);
