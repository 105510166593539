/*
 * @Author: 张博洋
 * @Date: 2021-09-05 17:29:46
 * @LastEditTime: 2021-09-05 17:34:06
 * @LastEditors: 张博洋
 * @Description:
 * @FilePath: /hospital-h5/src/router/pdf.js
 * 可以输入预定的版权声明、个性签名、空行等
 */
export default [
    {
        path: "/pdf/pdf",
        name: "pdf.pdf",
        meta: {
            title: "",
            route: {},
            AppNoLogin: true,
        },
        component: () => import("@v/pdf/pdf.vue"),
    },
];
