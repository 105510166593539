/*
 * @Author: 张博洋
 * @Date: 2021-07-15 16:26:23
 * @LastEditTime: 2021-07-15 16:37:10
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /hospital-h5/src/router/404.js
 * 可以输入预定的版权声明、个性签名、空行等
 */

export default [
    {
        //活动聚合页
        path: "*",
        name: "404",
        meta: {
            title: "访问页面丢失"
        },
        component: () => import("@v/404")
    }
];
