<template>
    <div class="no-select">
        <div class="flex-box middle f12 pd15 color-sub" v-if="error">
            <span>{{errorMsg}}</span> <span class="no-select color-primary pd-l3 pd-r3 text-nowrap"
                style="text-decoration:underline;" @click="$emit('again')">重试</span>
        </div>
        <div class="flex-box middle f12 pd15 color-sub over" v-else-if="loading" style="height:48px;">
            <van-loading size="16px" /> <span> <span class="ml10">加载中...</span></span>
        </div>

        <div class="flex-box middle f12 pd15" v-else-if="over">
            <img :src="$.moreLogo" alt="" style="height:14px;" class="no-pointer" v-if="!overTxt">
            <template v-else>
                <span style="height:2px;width:12px;" class="bg-sub opacity5"></span>
                <span class="color-sub ml5 mr5">{{overTxt}}</span>
                <span style="height:2px;width:12px;" class="bg-sub opacity5"></span>
            </template>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            loading: {
                default: false,
            },
            over: {
                default: false
            },
            error: {
                default: false,
            },
            errorMsg: {
                default: '请求失败！'
            },
            overTxt: {
                default: '没有更多了'
            }
        }
    }

</script>

<style scoped>

</style>
