<template>
  <div class="flex-box middle no-pointer pd15">
    <!-- <img :src="$.moreLogo" alt="" style="height:14px;"> -->
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
</style>
