<template>
  <div class="full">
    <div>
      <transition name="scale">
        <div class="full-pa index12 flex-box middle"
             style="top:0;height:50px;"
             v-show="typeShow"
             v-if="tip">
          <!-- <span class="bg-primary color-white tc pd5 radius6 f12 text-bold opacity8" style="min-width:50%;"
                    v-show="type==1">加载成功！</span> -->
          <span class="bg-title color-white tc pd5 radius6 f12 text-bold opacity8"
                style="min-width:50%;"
                v-show="type == 2">加载失败！</span>
        </div>
      </transition>
      <div class="pa full-w flex-box middle index10 no-pointer"
           style="top:0;left:0;height:50px;">
        <!-- {{Math.floor(this.tt)}},{{Math.floor(this.start.pageY)}},{{Math.floor(this.y)}},{{Math.floor(this.s)}} -->
        <!-- <span class="pa" style="opacity:0">{{this.tt}}</span> -->
        <my-loading class="scale8 mt-6"
                    :progress="progress"
                    v-if="loadingType == 'normal'"></my-loading>
        <simple-loading v-else-if="progress > 0"
                        class="simpleLoading"></simple-loading>

        <!-- <template v-if="loading">
                <van-loading size="16px" class="mr10" /> 正在加载...
            </template>
            <template v-else-if="downAction&&moveFlag&&tt>10">释放刷新</template>
            <template v-else-if="!downAction&&moveFlag&&tt>10">下拉刷新</template> -->
      </div>
      <div ref="downRefresh"
           :id="scrollId"
           :class="directionClass[direction]"
           class="pr full index10">
        <div class="downRefreshBody pr index1">
          <slot></slot>
        </div>
      </div>
      <!-- <transition name="returnUp">
        <div v-show="showUp"
             @click="goBack"
             class="pa index30 tran b radius-c flex-box middle bg-white actives goTopBtn"
             :class="goTopClass"
             style="bottom:20px;right:15px;width:35px;height:35px;color:#989ca5;">
          <van-icon name="arrows-up"
                    class-prefix="iconfont"
                    size="20" />
        </div>
      </transition> -->
    </div>
  </div>
</template>

<script>
import myLoading from "./loading";
import simpleLoading from "./simpleLoading";
export default {
  // inject: ['APP'],
  props: {
    scrollId: {
      default: ""
    },
    direction: {
      default: "y"
    },
    infinite: {
      //是否开启上拉加载更多
      default: false
    },
    refresh: Boolean, //是否开启下拉刷新
    upFlag: Boolean,
    goTopClass: {
      default: ""
    },
    goTopMD: {
      default: ""
    },
    tip: {
      default: true
    },
    loadingType: {
      default: "normal"
    },
    ajaxTime: {
      default: 400
    },
    refreshNum: {
      default: 200
    }
  },
  components: {
    myLoading,
    simpleLoading
  },
  computed: {
    progress () {
      if (this.typeShow && this.type == 1) {
        //更新成功
        return 3;
      } else if (this.loading) {
        //正在加载
        return 2;
      } else if (this.downAction && this.moveFlag && this.tt > 10) {
        //释放刷新
        return 1;
      } else if (!this.downAction && this.moveFlag && this.tt > 10) {
        //下拉刷新
        return this.tt / 50 > 1 ? 1 : this.tt / 50;
      } else {
        return 0;
      }
    }
  },
  data () {
    return {
      y: 0,
      s: 0,
      downNum: 2,
      showUp: false, //返回顶部flag
      directionClass: {
        x: "over-x",
        y: "over-y"
      },
      loading: false, //下拉请求数据中
      downAction: false, //下拉过程中flag
      //判断参数
      flag: 0,
      start: {},
      tt: 0, //下拉的偏移距离
      moveFlag: false,
      typeShow: false,
      type: 1, //下拉请求之后返回请求成功状态 0为需要隐藏，1为更新成功，2.更新失败,
      typeS: "",
      touchAction: {
        touchmoveAction: null,
        touchEndAction: null
      }
    };
  },
  watch: {
    refresh (v) {
      if (v) {
        this.init();
      } else {
        this.refreshRemove();
      }
    }
  },
  created () {
    this.$nextTick(() => {
      if (this.refresh) {
        this.init();
      }
      if (this.upFlag) {
        //需要返回顶部
        this.upFlagInit();
      }
    });
  },
  methods: {
    goBack () {
      this.$scrollTo(300, {
        offset: 0,
        container: this.$refs.downRefresh
      });
    },
    upFlagInit () {
      let el = this.$refs.downRefresh;
      el.addEventListener("scroll", () => {
        var s = el.scrollTop;
        if (s > 100) {
          this.showUp = true;
        } else {
          this.showUp = false;
        }
      });
    },
    refreshRemove () {
      //去除refresh事件
      // console.log('移除事件');
      this.init(true);
    },
    init (removeFlag) {
      //removeFlag 去除事件
      let el = this.$refs.downRefresh;
      var down = el.querySelector(".downRefreshBody");

      //  el.addEventListener('scroll',e=>{
      //      var s = el.scrollTop;
      //       if (s <= -1) {
      //         if (-s > this.downNum + 50) {
      //             this.downAction = true;
      //         } else {
      //             this.downAction = false;
      //         }
      //     }
      //  })
      if (removeFlag) {
        el.removeEventListener(
          "touchmove",
          this.touchAction.touchmoveAction
        );
        el.removeEventListener(
          "touchend",
          this.touchAction.touchEndAction
        );
        return false;
      }
      this.touchAction.touchmoveAction = e => {
        // console.log(111)
        // if(this.loading){//如果正在请求数据
        //     e.preventDefault();
        //     return false;
        // }
        if (this.loading) {
          // e.preventDefault;
          return false;
        }
        var s = el.scrollTop;
        this.s = s;
        let t = e.targetTouches[0];
        // if (s <= -1) {
        //     down.style.transform = `translate(0,0)`;
        //     this.tt = -s;
        //     if (this.tt > this.downNum + 50) {
        //         this.downAction = true;
        //     } else {
        //         this.downAction = false;
        //     }
        // } else {
        if (s <= this.downNum) {
          this.moveFlag = true;
          down.style.transition = "none";
          this.flag++;
          if (this.flag == 1) {
            // console.log(222)
            this.start = t;
          }
          // console.log(t.pageY, this.start.pageY)
          this.y = t.pageY;
          //处理iosbounce
          // if (s > -1) {
          this.tt = t.pageY - this.start.pageY;
          // }
          // console.log(this.tt, 2222)
          if (this.tt > 0) {
            //下拉动作
            // e.preventDefault();
            down.style.transform = `translate(0,${this.tt *
              0.7 *
              (1 - this.tt / document.body.clientHeight)}px)`;
          }
          if (this.tt > this.downNum + this.refreshNum) {
            this.downAction = true;
          } else {
            this.downAction = false;
          }
          // return false;
        } else {
          if (this.tt > 0) {
            this.flag = 0;
            this.tt = t.pageY - this.start.pageY;
            // console.log(this.tt, 2222)
            if (this.tt > 0) {
              //下拉动作
              // e.preventDefault();
              down.style.transform = `translate(0,${this.tt *
                (1 - this.tt / document.body.clientHeight)}px)`;
            }
            if (this.tt > this.downNum + this.refreshNum) {
              this.downAction = true;
            } else {
              this.downAction = false;
            }
          }
        }

        // }
      };
      this.touchAction.touchEndAction = () => {
        this.flag = 0;
        this.moveFlag = false;
        this.downAction = false; //下拉动作结束
        // var s = el.scrollTop;
        if (this.tt > this.downNum + this.refreshNum) {
          //大于50执行刷新方法
          down.style.transition = "transform 0.3s";
          down.style.transform = `translate(0,50px)`;
          //执行外部刷新方法
          if (!this.loading) {
            this.downRefresh();
          }
        } else {
          down.style.transition = "transform 0.3s";
          down.style.transform = `none`;
          this.start = {};
          this.tt = 0;
        }
      };

      el.addEventListener("touchmove", this.touchAction.touchmoveAction);
      el.addEventListener("touchend", this.touchAction.touchEndAction);
    },
    downRefresh () {
      //下拉刷新事件触发
      this.loading = true;
      this.$emit("downRefresh");
    },
    selfdownRefresh () {
      //手动触发下拉刷新
      let _self = this;
      this.$scrollTo(300, {
        offset: 0,
        container: _self.$refs.downRefresh,
        onDone () {
          //改变dom位置
          let el = _self.$refs.downRefresh;
          var down = el.querySelector(".downRefreshBody");
          down.style.transition = "transform 0.3s";
          down.style.transform = `translate(0,50px)`;
          //执行外部刷新方法
          if (!_self.loading) {
            _self.downRefresh();
          }
        }
      });
    },
    downRefreshOver (flag) {
      //下拉事件请求ajax完成之后

      setTimeout(() => {
        this.loading = false;
        let el = this.$refs.downRefresh;
        var down = el.querySelector(".downRefreshBody");
        down.style.transition = "transform 0.3s";
        down.style.transform = `none`;
        this.tt = 0;
        this.start = {};
        this.typeShow = true;
        this.type = flag ? 1 : 2;
        if (this.typeS) {
          clearTimeout(this.typeS);
        }
        this.typeS = setTimeout(() => {
          if (this) {
            this.typeShow = false;
          }
        }, 300);
      }, this.ajaxTime);
    },
    infiniteAction () {
      this.$emit("infinite");
    }
  }
};
</script>

<style lang="scss" scoped></style>
