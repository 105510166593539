<!--
 * @Author: 张博洋
 * @Date: 2021-01-05 17:46:52
 * @LastEditTime: 2021-09-13 12:51:02
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /hospital-h5/src/components/static/index.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->
<template>
  <!-- <navigation> -->
  <router-view :key='key'
               class="APPpageContent"
               v-if='showFlag'></router-view>
  <!-- </navigation> -->
</template>

<script>
export default {
  inject: ['APP'],
  provide () {
    // return {
    //     staticTmp: this
    // }
  },
  data () {
    return {
      key: 0,
      showFlag: false,
      n: 0,
    }
  },
  created () {
    // console.log(this.$route,2222)
    setTimeout(() => {
      this.showFlag = true
    }, 20)
  },
  activated () {
    this.$.reload = () => {
      this.APP.reloadFlag = true
      this.key++
      setTimeout(() => {
        this.APP.reloadFlag = false
      }, 100)
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
