<template>
  <div>
    <div v-infinite-scroll="infiniteAction"
         infinite-scroll-disabled="infinite"
         infinite-scroll-distance="distance">
      <slot></slot>
    </div>
    <app-empty ref="empty"
               :loading="APPloading"
               :error="APPerror"
               :empty="empty"
               v-bind="emptyProps"
               :errorMsg="APPerrorMsg"
               v-if="query[pageNo] <= 1 && firstFlag"></app-empty>
    <!-- <app-infiniteBottom :overTxt="overTxt"
                        :loading="APPloading"
                        @again="againAction"
                        :error="APPerror"
                        :errorMsg="APPerrorMsg"
                        :over="APPover"
                        v-else-if="APPloading || APPover" /> -->
  </div>
</template>

<script>
export default {
  props: {
    distance: {
      default: 100
    },
    query: {
      type: Object
    },
    data: {
      default: "d.data"
    },
    pageSize: {
      default: "pageSize"
    },
    pageNo: {
      default: "pageNo"
    },
    lastPage: {
      default: "d.isLastPage"
    },
    overTxt: {
      default: "没有更多了"
    },
    emptyProps: {
      default () {
        return {};
      }
    }
  },
  data () {
    return {
      infinite: true,
      empty: false,
      firstFlag: true
    };
  },
  created () {
    window.r = this;
  },
  methods: {
    resetInfinite () {
      //重试
      this.resetAPP();
      this.empty = false;
      this.firstFlag = true;
      this.setInfinite(false);
    },
    setInfinite (flag) {
      this.infinite = flag;
    },
    infiniteAction () {
      //触发事件
      this.infinite = true;
      this.setAPPloading(); //参数初始化
      this.query[this.pageNo]++;
      if (this.query[this.pageNo] == 1) {
        // this.firstFlag = true;
      }
      this.$emit("infiniteAction");
    },
    ajaxThen (res) {
      //数据加载成功之后
      console.log(this.data)
      this.resetAPP(); //重置请求等参数
      let d = this.$._.result(res, this.data);
      console.log(d)
      if (this.query[this.pageNo] >= 1 && d.length > 0) {
        window.aaaa = this;
        this.firstFlag = false;
      }
      if (this.query[this.pageNo] == 1 && d.length == 0) {
        this.empty = true;
      }
      let lastFlag = (() => {
        let flag = d.length < (this.query[this.pageSize] || 1);
        return flag;
        //如果后端有最后一页的flag
        if (this.lastPage) {
          return (
            flag ||
            this.$._.result(res, this.lastPage) ||
            d.length == 0
          );
        } else {
          return flag;
        }
      })();
      if (lastFlag) {
        //结束加载
        this.setAPPover();
        this.infinite = true;
      } else {
        this.infinite = false;
      }
    },
    ajaxError (error) {
      this.query[this.pageNo]--;
      this.infinite = true; //封锁滚动
      this.setAPPerror(error);
    },
    againAction () {
      //错误之后重试
      this.infinite = false;
    }
  }
};
</script>

<style lang="scss" scoped></style>
