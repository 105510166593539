/*
 * @Author: 张博洋
 * @Date: 2021-08-06 16:33:41
 * @LastEditTime: 2022-02-23 17:27:12
 * @LastEditors: 张博洋
 * @Description:
 * @FilePath: /yfz-h5/src/router/follow.js
 * 可以输入预定的版权声明、个性签名、空行等
 */
export default [
  {
    path: "/follow/list",
    name: "follow.list",
    meta: {
      edit: true,
      title: "患者随访",
      route: {},
      AppNoLogin: true
    },
    component: () => import("@v/follow/list.vue")
  },
  {
    path: "/follow/detail",
    name: "follow.detail",
    meta: {
      edit: true,
      title: "随访反馈表单",
      route: {},
      AppNoLogin: true
    },
    component: () => import("@v/follow/detail.vue")
  },
  {
    path: "/follow/fill",
    name: "follow.fill",
    meta: {
      edit: true,
      title: "随访反馈表单",
      route: {},
      AppNoLogin: true
    },
    component: () => import("@v/follow/fill.vue")
  },
];
