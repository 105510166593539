/*
 * @Author: 张博洋
 * @Date: 2021-07-12 19:57:57
 * @LastEditTime: 2021-09-26 11:11:34
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /hospital-h5/src/main.js
 * 可以输入预定的版权声明、个性签名、空行等
 */
import Vue from 'vue'
import App from './App.vue'
import router from "./router/index";
// import './assets/app.scss';

Vue.config.productionTip = false

import Navigation from 'vue-navigation'
Vue.use(Navigation, {
  router
});
//引入babel-polyfill
import "babel-polyfill";
//引入全局需要的组件以及方法
import "./public/vant";

import "./public/index.js";

import "./public/axios.js";

//引入libs插件
import InfiniteScroll from "./libs/infiniteScroll/index.js";
Vue.use(InfiniteScroll);

//引入滚动动画组件，但是组件有待考察兼容性
import VueScrollTo from "vue-scrollto";
Vue.use(VueScrollTo);

import FastClick from "fastclick";
FastClick.attach(document.body);


FastClick.prototype.focus = function (targetElement) {
  console.log(targetElement)
  let length;
  // if (
  //   targetElement.setSelectionRange &&
  //   targetElement.type.indexOf("date") !== 0 &&
  //   targetElement.type !== "time" &&
  //   targetElement.type !== "month"
  // ) {
  //   length = targetElement.value.length;
  //   targetElement.focus();
  //   targetElement.setSelectionRange(length, length);
  // } else {
  //   targetElement.focus();
  // }

  var deviceIsWindowsPhone = navigator.userAgent.indexOf("Windows Phone") >= 0;
  var deviceIsIOS = /iP(ad|hone|od)/.test(navigator.userAgent) && !deviceIsWindowsPhone;
  //兼容处理:在iOS7中，有一些元素（如date、datetime、month等）在setSelectionRange会出现TypeError    
  //这是因为这些元素并没有selectionStart和selectionEnd的整型数字属性，所以一旦引用就会报错，因此排除这些属性才使用setSelectionRange方法
  if (deviceIsIOS && targetElement.setSelectionRange && targetElement.type.indexOf('date') !== 0 && targetElement.type !== 'time' && targetElement.type !== 'month' && targetElement.type !== 'email') {
    length = targetElement.value.length;
    targetElement.setSelectionRange(length, length); //修复bug ios 11.3不弹出键盘，这里加上聚焦代码，让其强制聚焦弹出键盘    
    targetElement.focus();
  } else {
    targetElement.focus();
  }
};



//全局引入swiper，因为页面大量使用到，且vant本身swiper性能不好
import VueAwesomeSwiper from "vue-awesome-swiper";
// require styles
import "swiper/dist/css/swiper.css";
Vue.use(VueAwesomeSwiper /* { default global options } */);

import "./assets/variables.scss"

import $ from "./public/utils.js";
// import { get } from "http";
Vue.use($);

new Vue({
  render: h => h(App),
  router: router,
}).$mount('#app')
