/*
 * @Author: 张博洋
 * @Date: 2021-07-22 14:52:04
 * @LastEditTime: 2021-12-28 17:31:58
 * @LastEditors: 张博洋
 * @Description:
 * @FilePath: /yfz-h5/src/router/city.js
 * 可以输入预定的版权声明、个性签名、空行等
 */
export default [
    {
        path: "/case",
        name: "case",
        meta: {
            done: false,
            title: "电子病历",
            route: {},
        },
        component: () => import("../views/case/case.vue"),
    },
];
