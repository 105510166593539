/*
 * @Author: 张博洋
 * @Date: 2021-07-26 14:22:42
 * @LastEditTime: 2021-08-17 18:47:06
 * @LastEditors: 张博洋
 * @Description:
 * @FilePath: /hospital-h5/src/router/consult.js
 * 可以输入预定的版权声明、个性签名、空行等
 */
export default [
  {
    path: "/consult/edit/",
    name: "consult.edit",
    meta: {
      edit: true,
      title: "填写咨询单",
      route: {
        params: {
          name: ''
        }
      },
      AppNoLogin: true
    },
    component: () => import("@v/consult/edit.vue")
  }
];
