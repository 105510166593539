/*
 * @Author: 张博洋
 * @Date: 2021-08-09 11:45:04
 * @LastEditTime: 2021-08-09 11:45:34
 * @LastEditors: 张博洋
 * @Description:
 * @FilePath: /hospital-h5/src/mixin/Loading.js
 * 可以输入预定的版权声明、个性签名、空行等
 */
import { Loading } from 'vant';

export default {
  components: (() => {
    let obj = {};
    obj[Loading.name] = Loading;
    return obj;
  })(),
}