/*
 * @Author: 张博洋
 * @Date: 2021-07-12 19:57:57
 * @LastEditTime: 2021-08-15 11:30:08
 * @LastEditors: 张博洋
 * @Description:
 * @FilePath: /hospital-h5/src/router/doctor.js
 * 可以输入预定的版权声明、个性签名、空行等
 */
export default [
  {
    path: "/doctor/list",
    name: "doctor.list",
    meta: {
      edit: true,
      title: "全部医生",
      route: {},
      AppNoLogin: true
    },
    component: () => import("@v/doctor/list.vue")
  },
  {
    path: "/doctor/detail",
    name: "doctor.detail",
    meta: {
      edit: true,
      title: "医生详情",
      route: {},
      AppNoLogin: true
    },
    component: () => import("@v/doctor/detail.vue")
  }
];
