import Vue from 'vue'
import aLink from "@c/link/index.vue";
Vue.component("aLink", aLink);

import $ from "./utils.js";
//下拉刷新
import appRefresh from "@c/refresh";
Vue.component("appRefresh", appRefresh);

//下拉加载
import appInfinite from "@c/infinite";
Vue.component("appInfinite", appInfinite);

//数据结束img
import appOver from "@c/infinite/over.vue";
Vue.component("appOver", appOver);

import appInfiniteBottom from "@c/refresh/infiniteBottom.vue";
Vue.component("appInfiniteBottom", appInfiniteBottom);

import appEmpty from "@c/refresh/empty.vue";
Vue.component("appEmpty", appEmpty);


Vue.directive("notouch", {
  // 当被绑定的元素插入到 DOM 中时……
  bind: function (el) {
    // 聚焦元素
    el.addEventListener("touchmove", function (e) {
      e.preventDefault();
    });
  }
});


Vue.mixin({
  data () {
    return {
      APPScroll: [],
      APPloading: true,
      APPerror: false,
      APPerrorMsg: "",
      APPover: false,
      APPuploadLoading: "",
      btnLoading: false
    };
  },
  created () {
    // this.APPscrollInit();
  },
  activated () {
    this.APPscrollInit();
    // this.$nextTick(() => {
    //     try {
    //         if (this.$el.classList.contains('APPpageContent')) {
    //             console.log(this.$el)
    //         }
    //     } catch (error) {

    //     }
    // })
  },
  deactivated () {
    this.APPScrollStart();
    this.APPScroll.forEach(e => {
      e.x = e.scrollLeft;
      e.y = e.scrollTop;
    });
  },
  methods: {
    inputMoney (item, type) {
      if (item[type]) {
        item[type] = Number(item[type]).toFixed(2) || "";
      }
    },
    APPscrollInit () {
      this.$nextTick(() => {
        this.APPScrollStart();
        this.APPScroll.forEach(e => {
          // console.log(e.x, e.y)
          e.scrollTo(e.x || 0, e.y || 0);
        });
        if (this.$route && this.$route.meta) {
          this.$root.title = this.$route.meta.title || "";
        }
      });
    },
    resetAPP () {
      this.APPloading = true;
      this.APPerror = false;
      this.APPover = false;
      this.APPerrorMsg = "";
    },
    setAPPempty (empty) {
      this.APPloading = false;
      this.APPerror = false;
      this.APPover = true;
      this.APPerrorMsg = empty || "暂无数据";
    },
    setAPPerror (error) {
      this.APPloading = false;
      this.APPerror = true;
      this.APPover = true;
      this.APPerrorMsg = error || "请求失败";
    },
    setAPPloading () {
      this.APPloading = true;
      this.APPerror = false;
      this.APPover = false;
      this.APPerrorMsg = "";
    },
    setAPPover () {
      this.APPloading = false;
      this.APPerror = false;
      this.APPover = true;
      this.APPerrorMsg = "";
    },
    APPScrollStart () {
      //获取有滚动条的元素
      if (this.$el && this.$el.tagName) {
        this.APPScroll = this.$el.querySelectorAll(".over-y,.over-x");
      } else {
        //this.$el 获取到了html 注释节点
      }

      // console.log(this.APPScroll)
    },
    appNavLeftClick () {
      // setTimeout(() => {
      this.$.goBack();
      // }, 300)
    }
  }
});
Vue.directive("rotate", {
  bind (el, { value }) {
    el.style.transform = `rotate(${value ? 180 : 0}deg)`;
    el.style.transition = `transform 0.25s ease`;
  },
  update (el, { value }) {
    el.style.transform = `rotate(${value ? 180 : 0}deg)`;
  }
});
Vue.filter("fixed", (value, n) => {
  if (value == null) {
    return "--";
  }
  n = n || 2;
  return Number(value || "").toFixed(n);
});
// 'YYYY.MM.DD HH:mm:ss';
Vue.filter("time", (value, n) => {
  n = n || "YYYY年MM月DD日";
  return $.getTime(value, n);
});
Vue.filter("time1", (value, n) => {
  n = n || "YYYY.MM.DD";
  return $.getTime(value, n);
});
Vue.filter("mmdd", (value, n) => {
  n = n || "MM月DD日";
  return $.getTime(value, n);
});
Vue.filter("padDate", value => {
  return value < 10 ? "0" + value : value;
});
Vue.filter("tenNum", value => {
  return value < 10 ? `0${value}` : value;
});
Vue.filter("telStar", value => {
  // 原文链接：https://blog.csdn.net/My_ben/java/article/details/83538020
  function mobilePhoneMask (input) {
    var output = input;
    if (input == null || input == "") return output;
    if (input.length > 6) {
      output =
        input.substr(0, 3) + "*****" + input.substr(input.length - 3);
    } else if (input.length > 3) {
      output = input.substr(0, 3) + "*****";
    } else if (input.length > 0) {
      output = input.substr(0, 1) + "*****";
    }
    return output;
  }
  return mobilePhoneMask(value);
});
