/*
 * @Author: 严浩韦
 * @description:简单loading动画，用于im聊天
 * @Date: 2020-04-25 20:27:34
 * @Last Modified by: 严浩韦
 * @Last Modified time: 2020-04-25 20:28:33
 */
<template>
    <div class="flex-box middle-a">
        <van-loading size="14" />
    </div>
</template>

<script>
    export default {

    }
</script>

<style lang="scss" scoped>

</style>
