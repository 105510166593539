/*
 * @Author: 张博洋
 * @Date: 2021-07-12 19:57:57
 * @LastEditTime: 2022-03-08 11:23:52
 * @LastEditors: 张博洋
 * @Description:
 * @FilePath: /yfz-h5/src/router/index.js
 * 可以输入预定的版权声明、个性签名、空行等
 */
import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};

const originalReplace = Router.prototype.replace;
Router.prototype.replace = function replace(location) {
    return originalReplace.call(this, location).catch((err) => err);
};

let arr = [];

import staticTmp from "@c/static";

// // 医生模块
// import adressRouter from "./adress";
// arr.push(...adressRouter);

// 医生模块
import doctorRouter from "./doctor";
arr.push(...doctorRouter);

// 咨询模块
import consultRouter from "./consult";
arr.push(...consultRouter);

// 医疗订单
import orderRouter from "./order";
arr.push(...orderRouter);

// 医疗订单
import drugRouter from "./drug";
arr.push(...drugRouter);

// 就诊人
import patientRouter from "./patient";
arr.push(...patientRouter);

// im
import imRouter from "./im";
arr.push(...imRouter);

// 协议
import agreeRouter from "./pdf";
arr.push(...agreeRouter);

// 地址管理
import addressRouter from "./address";
arr.push(...addressRouter);

// 城市选择
import cityRouter from "./city";
arr.push(...cityRouter);

// 量表
import checkRouter from "./check";
arr.push(...checkRouter);

// 消息
import messageRouter from "./message";
arr.push(...messageRouter);

// 随访
import followRouter from "./follow";
arr.push(...followRouter);

import archivesRouter from "./archives";
arr.push(...archivesRouter);

import caseRouter from "./case";
arr.push(...caseRouter);

import rpRouter from "./rp";
arr.push(...rpRouter);

import agreementRouter from "./agreement";
arr.push(...agreementRouter);

let routesArr = [
    {
        path: "/",
        name: "app",
        redirect: {
            name: "routes",
        },
        meta: {
            title: "...",
        },
        component: staticTmp,
        children: arr,
    },
    {
        path: "/routes",
        name: "routes",
        meta: {
            title: "项目路由",
            AppNoLogin: true,
        },
        component: () => import("@v/routes/index.vue"),
    },
];

//正常路由需要放到404之前
import notFindRoutes from "./404.js";

const router = new Router({
    mode: "history",
    // mode: "hash",
    base: "/",
    routes: routesArr.concat(notFindRoutes),
});
export default router;
