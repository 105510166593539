/*
 * @Author: 张博洋
 * @Date: 2022-01-08 19:43:48
 * @LastEditTime: 2022-01-08 19:45:38
 * @LastEditors: 张博洋
 * @Description:
 * @FilePath: /h5/src/router/message.js
 * 可以输入预定的版权声明、个性签名、空行等
 */
export default [
  {
    path: "/message/list",
    name: "message.list",
    meta: {
      done: false,
      title: "消息",
      route: {}
    },
    component: () => import("../views/message/list.vue")
  },
];
