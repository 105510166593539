
<template>
  <div>
    <div v-if="loading"
         style="height:300px;"
         class="flex-box middle app-loading">
      <van-loading size="40" />
    </div>
    <div class="tc f14"
         style="color: #CACCD0"
         v-if='empty&&!loading&&!error'>
      <img style="margin-top:calc(50% - 80px)" :src="nowemptyImg"
           :style="{width:imgWidth}">
      <br>
      <span class="block mt20"
            style="font-size:14px">{{emptyTxt}}</span>
    </div>
    <div class="tc f14"
         style="padding:30px 15px;"
         v-if='error&&!loading'>
      <img :src="errorImg"
           :style="{width:imgWidth}">
      <br /><br /> {{errorMsg||'服务器出错，请稍后再试！'}}
      <div class='flex-box middle'>
        <template v-if='showBack'>
          <van-button type="primary"
                      plain
                      round
                      class="mt10 ml5 mr5 btn"
                      size='small'
                      @click='$.goBack'>返回
          </van-button>
        </template>
        <template v-if='again||againFn'>
          <van-button round
                      class="mt10 ml5 mr5 btn"
                      plain
                      size='small'
                      type="primary"
                      @click='againAction'>
            重试
          </van-button>
        </template>
      </div>
    </div>
  </div>

</template>

<script>
import Vue from "vue";
import vanLoading from '@/mixin/Loading.js'
// import vatIcon from '@/mixin/Icon.js'
import { Icon } from 'vant';
Vue.use(Icon);
export default {
  // inject: ['APP'],
  mixins: [vanLoading],
  props: {
    loading: {
      type: Boolean,
      default: true
    },
    imgWidth: {
      default: '120px'
    },
    emptyTxt: {
      default: '暂无订单～'
    },
    empty: {
      default: false
    },
    emptyImg: {
      default () {
        return require('@/static/img/order-empty.png')
      },
    },
    searchEmpty: {
      default () {
        // return this.$.imgs.lose.search;
      },
    },
    error: {
      type: Boolean,
      default: false
    },
    errorMsg: {
      // type: String,
      default: ''
    },
    errorImg: {
      default () {
        // return require('@/static/img/wifi.svg')
      },
    },
    height: {
      defautl: '200px'
    },
    type: {
      default: 1 //1为正常空，2为搜索为空
    },
    showBack: { //是否显示返回按钮
      default: false,
    },
    again: {
      default: false,
    },
    againFn: {
      default: null,
    }
  },
  data () {
    return {

    }
  },
  created () {
    window.a = this;
  },
  computed: {
    nowemptyImg () {
      return this.emptyImg
    },
  },
  methods: {
    againAction () {
      if (this.againFn) {
        this.againFn();
      } else {
        this.$.reload();
      }
    }
  },
}

</script>

<style scoped>
</style>
