/*
 * @Author: 张博洋
 * @Date: 2021-07-22 14:52:04
 * @LastEditTime: 2021-11-02 16:52:36
 * @LastEditors: 张博洋
 * @Description:
 * @FilePath: /hospital-h5/src/router/check.js
 * 可以输入预定的版权声明、个性签名、空行等
 */
export default [
  {
    path: "/check/index",
    name: "check.index",
    meta: {
      done: false,
      title: "",
      route: {}
    },
    component: () => import("../views/check/index.vue")
  },
  {
    path: "/check/detail",
    name: "check.detail",
    meta: {
      done: true,
      hide: true,
      title: "",
      route: {}
    },
    component: () => import("@/views/check/detail.vue")
  },
  {
    path: "/check/result",
    name: "check.result",
    meta: {
      done: true,
      hide: true,
      title: "",
      route: {}
    },
    component: () => import("@/views/check/result.vue")
  }
];

