/*
 * @Author: 张博洋
 * @Date: 2021-08-06 16:33:41
 * @LastEditTime: 2021-08-10 14:12:54
 * @LastEditors: 张博洋
 * @Description:
 * @FilePath: /hospital-h5/src/router/order.js
 * 可以输入预定的版权声明、个性签名、空行等
 */
export default [
  {
    path: "/order/list",
    name: "order.list",
    meta: {
      edit: true,
      title: "医疗订单",
      route: {},
      AppNoLogin: true
    },
    component: () => import("@v/order/list.vue")
  },
  {
    path: "/order/detail",
    name: "order.detail",
    meta: {
      edit: true,
      title: "订单详情",
      route: {},
      AppNoLogin: true
    },
    component: () => import("@v/order/detail.vue")
  }
];
