export default [
    {
        path: "/archives/archives",
        name: "archives.archives",
        meta: {
            done: false,
            title: "健康档案",
            route: {},
        },
        component: () => import("../views/archives/archives.vue"),
    },
    {
        path: "/archives/modify",
        name: "archives.edit",
        meta: {
            done: false,
            title: "健康档案",
            route: {},
        },
        component: () => import("../views/archives/modify.vue"),
    },
];
